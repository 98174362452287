import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
// import useGoogleSheets from "use-google-sheets"

const DATA = [
  {
    Question: "What is WelcomeChurch.ca?",
    Owner: "",
    Role: "",
    Answer:
      "WelcomeChurch.ca provides online training and resources to equip Canadian churches to welcome and support newcomers to Canada.  Through this website we are also able to connect newcomers to registered 'Welcome Churches' through our on-line portals. Welcome Church is a project of Our Common Calling.",
    Email: "",
    URL1: "https://welcomechurch.ca/",
    URL2: "https://www.canhk.info/",
    URL3: "",
    URL4: "",
    URL5: "",
    "Is Viewable": "Yes",
  },
  {
    Question: "What is CanRefugee?",
    Owner: "",
    Role: "",
    Answer:
      "CanRefugee is one part of the Welcome Church project with a specific focus on providing resources for churches wanting to welcome and support people arriving in Canada as refugees and refugee claimants.",
    Email: "",
    URL1: "Can Refugee URL",
  },
  {
    Question: "What is Our Common Calling?",
    Owner: "",
    Role: "",
    Answer:
      "Our Common Calling (OCC) is a partnership of the Evangelical Fellowship of Canada (the EFC), Lausanne Canada, the Canadian Centre for Christian Charities (the 4Cs), and Christian Higher Education Canada (CHEC).   These four national organizations work together through OCC to encourage collaboration and innovation across Canada through projects like Welcome Church.",
    Email: "",
    URL1: "https://www.ourcommoncalling.ca/",
    URL2: "https://www.evangelicalfellowship.ca/",
    URL3: "https://www.cccc.org/\n",
    URL4: "https://www.checanada.ca/",
    URL5: "",
    "Is Viewable": "Yes",
  },
  {
    Question:
      "How will Welcome Church help my church or faith community to welcome refugees to Canada more effectively?",
    Owner: "",
    Role: "",
    Answer:
      "The Welcome Church project provides training and resources through our secure website for churches interested in welcoming newcomers to Canada.  Churches and faith communities who register with WelcomeChurch.ca will be given access to the Welcome Church secure training portal. After completing the inintial Welcome Church training you then have the opportunity to access specialized training to better prepare you to welcome those arriving in Canada as refugees.",
    Email: "",
    URL1: "",
    URL2: "",
    URL3: "",
    URL4: "",
    URL5: "",
    "Is Viewable": "Yes",
  },
  {
    Question:
      "What is involved in the Welcome Church CanRefugee training and how long does it take to complete the training?",
    Owner: "",
    Role: "",
    Answer:
      "Currently, the refugee training program consists of five videos with discussion questions for each video. Each video is between 5 and 10 minutes long. We encourage you to watch the videos and discuss the follow-up questions with others from your church.",
    Email: "",
    URL1: "",
    URL2: "",
    URL3: "",
    URL4: "",
    URL5: "",
    "Is Viewable": "Yes",
  },
  {
    Question:
      "Who should take the training?  Is the training for just one person or for the whole church?",
    Owner: "",
    Role: "",
    Answer:
      "The training resources may be used by one person or with a small group of individuals.  We encourage churches to go through the training with a group whenever possible. The interactive conversation with others in your church is a key part of the learningand will help you apply the learning to your particular context.  The online assessment at the end of the training can either be completed by everyone who participated in the sessions or by one person on behalf of the group. ",
    Email: "",
    URL1: "",
    URL2: "",
    URL3: "",
    URL4: "",
    URL5: "",
    "Is Viewable": "Yes",
  },
  {
    Question: "What happens once we've completed the Welcome Church training?",
    Owner: "",
    Role: "",
    Answer:
      "Churches or faith communities that complete the Welcome Church training, will be listed in the on-line directory, which will allow newcomers to Canada to find information about your church. You will also have the opportunity to communicate with other Welcome Churches in the network through our secure portal (still in development).",
    Email: "",
    URL1: "",
    URL2: "",
    URL3: "",
    URL4: "",
    URL5: "",
    "Is Viewable": "Yes",
  },
  {
    Question:
      "Where else can we find resources to help our church welcome refugees?",
    Owner: "",
    Role: "",
    Answer:
      "In addition to the Welcome Church website, there are christian organizations and networks who are eager to help your church engage with refugees in meaningful ways. The Refugee Highway Partnership North America hosts regional events to bring together Christians involved in ministry with refugees. International Association for Refugees offers more indepth training and consultation for churches who want to learn more.",
    Email: "",
    URL1: "https://www.rhpna.com/",
    URL2: "https://www.iafr.ca/",
  },
  {
    Question:
      "If I have questions or concerns about this Welcoming Refugees training project, who do I contact?",
    Owner: "",
    Role: "",
    Answer: "Write to info@welcomechurch.ca with your questions or comments.",
    Email: "info@welcomechurch.ca",
    URL1: "",
    URL2: "",
    URL3: "",
    URL4: "",
    URL5: "",
    "Is Viewable": "Yes",
  },
]

const Faq = () => {
  const [faq, setFaq] = useState([])
  const loading = false
  const error = null
  useEffect(() => {
    setFaq(DATA)
  }, [])

  // const { data, loading, error } = useGoogleSheets({
  //   apiKey: process.env.GATSBY_GOOGLE_SHEETS_API_KEY,
  //   sheetId: "1727oJc3L6GE4BR1_B2pSj6rlp20QlxDxbHfpxmVICk8",
  // })

  // useEffect(() => {
  //   if (data && data[0] && data[0].data) {
  //     setFaq(data[0].data)
  //   }
  // }, [data])

  return (
    <Layout>
      <section className="header">
        <h1 className="header__title">Frequently Asked Questions</h1>
        <p className="header__description"></p>
      </section>
      <section className="faq">
        {loading && <p className="faq__message">Loading questions...</p>}
        {error && (
          <p className="faq__message">
            Could not load questions! Please come back later.
          </p>
        )}
        {faq.map((row, index) => (
          <div key={index}>
            <strong>{row.Question}</strong>
            <p>{row.Answer}</p>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export default Faq
